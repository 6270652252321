export const EVENT_LIST = {
  PIZZA_DAY_1: 'PIZZA_DAY_1',
  PIZZA_DAY_2: 'PIZZA_DAY_2',
};

export const initialEvents = {
  events: [
    {
      name: EVENT_LIST.PIZZA_DAY_1,
      dateRange: {
        start: '2024-05-20T20:00:00',
        end: '2024-05-23T23:59:59',
      },
      isInGlobalModal: true,
    },
    {
      name: EVENT_LIST.PIZZA_DAY_2,
      dateRange: {
        start: '2024-05-24T00:00:00',
        end: '2024-05-26T00:00:00',
      },
      isInGlobalModal: true,
    },
  ],
};
