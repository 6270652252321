export const EVENT_TYPE = {
  BUTTON_CLICK: 'button_click',
  LINK_CLICK: 'link_click',
};

export const EVENT_CATEGORY = {
  Campaign: 'campaign',
  CampaignTitle: 'campaign-title',
  Product: 'product', // Product related events
  Firmware: 'firmware', // Firmware download, upgrade, verify
  Roadmap: 'roadmap',
  Notification: 'notification',
  Cart: 'cart',
  Reseller: 'reseller',
  Modal: 'modal',
  HomeLink: 'home-link',
  ProductLink: 'product-link',
};
