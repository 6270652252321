import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ProductsConfig } from 'config/products';
import Cart from 'components/icons/Cart';
import useIsMobile from 'hooks/useIsMobile';
import { useIntl } from 'react-intl';
import {
  trackCampaignModalAddToCart,
  trackCampaignModalView,
} from 'tracking/click';
import { logger } from 'logger';
import useCartStore from 'stores/cart';
import { CountDown } from './CountDown';
import {
  ActionButton,
  Container,
  CountDownContainer,
  MainImage,
  ModalContainer,
  ModalContentWrapper,
  ProductBox,
  ProductBoxRow,
  ProductDiscount,
  ProductImage,
  PromotionTitle,
  StyledEllipse,
  StyledEllipse2,
  ZhTitle,
} from './style';
import useMessages from './useMessages';

export function Modal({ isOpen, onClose, event }) {
  const messages = useMessages();
  const intl = useIntl();
  const isMobile = useIsMobile();
  const namePattern = /^(.*)_(\d+)$/;
  const eventName = event?.name?.match(namePattern)?.[1];
  const phase = event?.name.slice(-1);

  const [currentId, setCurrentId] = useState();

  const close = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const { addToCart } = useCartStore();

  const isZh = useMemo(() => intl.locale === 'zh', [intl.locale]);

  const products = [
    {
      image: isMobile
        ? isZh
          ? require('./images/3pro-zh-m.png')
          : require('./images/3pro-m.png')
        : require('./images/3pro.png'),
      discount: isZh
        ? require('./images/3proDiscount-zh.png')
        : require('./images/3proDiscount.png'),
      variantId: ProductsConfig.k3Pro.variantId,
    },
  ];

  const addProduct = useCallback(
    async (variantId) => {
      try {
        setCurrentId(variantId);
        await addToCart(variantId);
        trackCampaignModalAddToCart(eventName, variantId);
        onClose?.();
      } catch (e) {
        logger.error(e);
      }
    },
    [addToCart, eventName, onClose],
  );

  useEffect(() => {
    if (!eventName)
      return;
    trackCampaignModalView(eventName);
  }, [eventName]);

  if (!event) {
    return null;
  }

  return (
    <ModalContainer
      open={isOpen}
      defaultOpen
      centered
      transition="scale br"
      closeIcon
      onClose={close}
    >
      <ModalContentWrapper>
        <MainImage />
        {isZh && <ZhTitle>披萨节特惠</ZhTitle>}
        <StyledEllipse />

        <CountDownContainer>
          <PromotionTitle>{messages.campaigns[phase - 1]}</PromotionTitle>
          <CountDown endTime={event.dateRange.end} onComplete={onClose} />
        </CountDownContainer>

        <Container>
          {products.map((it) => (
            <ProductBox key={it.variantId}>
              <ProductBoxRow>
                <ProductImage src={it.image} />
                {!isMobile && <ProductDiscount src={it.discount} />}
              </ProductBoxRow>
              <ActionButton
                onClick={() => addProduct(it.variantId)}
                disabled={currentId === it.variantId}
                arrow={false}
              >
                <Cart />
                {messages.button}
              </ActionButton>
            </ProductBox>
          ))}
          {!isMobile && <StyledEllipse2 />}
        </Container>
      </ModalContentWrapper>
    </ModalContainer>
  );
}
