import React from 'react';
import CountdownNow from 'react-countdown-now';
import dayjs from 'plugins/dayjs';
import { TZ } from 'hooks/useCountdown';
import useMessages from './useMessages';
import { Block, BlockNumber, BlockUnit, Container } from './style';

export function CountDown({ endTime, onComplete }) {
  const messages = useMessages();

  return (
    <CountdownNow
      zeroPadLength={2}
      date={dayjs.tz(endTime, TZ).utc()}
      onComplete={onComplete}
      renderer={({ formatted }) => {
        return (
          <Container>
            {['days', 'hours', 'minutes', 'seconds'].map((it) => (
              <Block key={it}>
                <BlockNumber>{formatted[it]}</BlockNumber>
                <BlockUnit>{messages[it]}</BlockUnit>
              </Block>
            ))}
          </Container>
        );
      }}
    />
  );
}
