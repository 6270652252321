import styled from 'styled-components';
import { DEVICE_QUERY_MOBILE } from 'utils/constants';
import { H1a, H2, H3Style, H5a, H7aStyle } from '../../components/styles/title';

export const Page = styled.div.attrs({
  className: 'theme-dark',
})`
  margin-top: calc(0px - var(--height-header));
  background: var(--color-bg-default);
`;

export const BaseSubtitle = styled(H5a)`
  color: var(--color-bd-cyan);
`;

export const BaseTitle = styled(H1a)`
  color: var(--color-fg-default);
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto 160px;
  @media${DEVICE_QUERY_MOBILE} {
    padding: 0 12px;
  }
`;

export const Title = styled(H2)`
  color: var(--color-fg-default);
  @media ${DEVICE_QUERY_MOBILE} {
    ${H3Style}
    margin-bottom: 80px;
    padding: 0 24px;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    + .image_content .image-title-h3-kt {
      margin-top: 64px;
    }
  }
`;

export const SubTitle = styled(H5a)`
  width: 350px;
  margin: 16px 0 80px 0;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  background: var(--color-gd-variant);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  @media ${DEVICE_QUERY_MOBILE} {
    ${H7aStyle}
    width: 100%;
    margin: 16px 0;
    padding: 0 24px;
    text-align: center;
  }
`;

export const Heighlight = styled.span`
  background: linear-gradient(90deg, #1be0c6 0%, #1dbbf5 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const PresaleHeighlight = styled.span`
  background: var(--color-gd-orange);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;
