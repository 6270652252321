import { useSpring } from '@react-spring/web';
import { useEffect, useState } from 'react';
import dayjs from '../../../plugins/dayjs';

const animDefaultConfig = {
  from: {
    opacity: 0,
    transform: 'translateY(80px)',
  },
  to: {
    opacity: 1,
    transform: 'translateY(0)',
  },
};

function useDelayAnimation(duration, delay) {
  const [style, api] = useSpring(
    () => ({
      ...animDefaultConfig,
      config: {
        duration,
      },
      delay,
    }),
    [],
  );
  return [style, api];
}

export function useBannerAnimation() {
  const [isShowNewsTip, setIsShowNewsTip] = useState(false);
  useEffect(() => {
    const TZ = 'Asia/Shanghai';
    const currTime = dayjs();
    const startTime = dayjs.tz('2024-08-22 08:00:00', TZ);
    const endTime = dayjs.tz('2024-09-05 08:00:00', TZ);
    if (currTime.isBetween(startTime, endTime)) {
      setIsShowNewsTip(true);
    }
  }, []);

  const [fadeUpStyleDelay300, fadeUpStyleDelay300Api] = useDelayAnimation(
    500,
    300,
  );
  const [fadeUpStyleDelay500, fadeUpStyleDelay500Api] = useDelayAnimation(
    500,
    500,
  );
  const [fadeUpStyleDelay700, fadeUpStyleDelay700Api] = useDelayAnimation(
    500,
    700,
  );
  const [fadeUpStyleDelay650, fadeUpStyleDelay650Api] = useDelayAnimation(
    600,
    650,
  );
  const [fadeUpStyleDelay800, fadeUpStyleDelay800Api] = useDelayAnimation(
    700,
    800,
  );
  const [fadeUpStyleDelay850, fadeUpStyleDelay850Api] = useDelayAnimation(
    700,
    850,
  );

  useEffect(() => {
    isShowNewsTip && fadeUpStyleDelay300Api.pause();
    fadeUpStyleDelay500Api.pause();
    fadeUpStyleDelay700Api.pause();
    fadeUpStyleDelay650Api.pause();
    fadeUpStyleDelay800Api.pause();
    fadeUpStyleDelay850Api.pause();
    setTimeout(() => {
      isShowNewsTip && fadeUpStyleDelay300Api.resume();
      fadeUpStyleDelay500Api.resume();
      fadeUpStyleDelay700Api.resume();
      fadeUpStyleDelay650Api.resume();
      fadeUpStyleDelay800Api.resume();
      fadeUpStyleDelay850Api.resume();
    }, 300);
  }, [
    fadeUpStyleDelay300Api,
    fadeUpStyleDelay500Api,
    fadeUpStyleDelay650Api,
    fadeUpStyleDelay700Api,
    fadeUpStyleDelay800Api,
    fadeUpStyleDelay850Api,
    isShowNewsTip,
  ]);

  return {
    isShowNewsTip,
    fadeUpStyleDelay300,
    fadeUpStyleDelay500,
    fadeUpStyleDelay700,
    fadeUpStyleDelay650,
    fadeUpStyleDelay800,
    fadeUpStyleDelay850,
  };
}
