import styled from 'styled-components';
import { DEVICE_QUERY_MOBILE } from 'utils/constants';
import { H8 } from 'components/styles/title';

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Mont';
  width: 145px;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-100%);
    right: 0;
    display: block;
    width: 1px;
    height: 16px;
    background: var(--color-fg-default);
    opacity: 0.2;
    @media ${DEVICE_QUERY_MOBILE} {
      transform: translateY(-30%);
    }
  }

  @media ${DEVICE_QUERY_MOBILE} {
    width: 80px;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 130px;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;

  @media ${DEVICE_QUERY_MOBILE} {
    height: 90px;
  }

  ${Block} {
    :last-child {
      ::after {
        display: none;
      }
    }
  }
`;

export const BlockNumber = styled.span`
  width: 84px;
  display: block;
  margin-top: 18px;
  text-align: center;
  font-size: 43px;
  font-family: Mont;
  font-style: normal;
  font-weight: 600;
  padding: 1px 14px;
  border-radius: 22px;
  background: #ffe8ce;
  color: #ff7f33;

  @media ${DEVICE_QUERY_MOBILE} {
    width: 60px;
    font-size: 24px;
    line-height: normal;
    letter-spacing: 0.48px;
  }
`;

export const BlockUnit = styled(H8).attrs({ as: 'span' })`
  display: block;
  margin-top: 7px;
  text-align: center;
  text-transform: uppercase;

  color: #fff;
  text-shadow: 1.004px 1.825px 0px #5f5f5f;
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;

  @media ${DEVICE_QUERY_MOBILE} {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 160% */
  }
`;
