import {
  addProduct,
  createShopCart,
  queryCart,
  removeProduct,
  updateProduct,
} from 'api/cart';
import { logger } from 'logger';
import {
  updateCartItemsSoldOutByStore,
  updateCartListPrice,
} from 'pages/Cart/utils';
import { getRecommendList } from 'pages/Shop/Details/productRelevanceList';
import { trackAddToCart } from 'tracking/ecommerce';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { createComputed } from 'zustand-computed';
import { sumBy } from 'lodash';
import useProductsStore from './products';

const computed = createComputed((state) => ({
  quantity: sumBy(state.list, 'quantity'),
}));

const useCartStore = create(
  devtools(
    persist(
      computed((set, get) => ({
        isOpen: false,
        id: '',
        isIdGetting: false,
        list: [],
        loading: false,
        cartData: {},
        recommendList: [],
        openCart() {
          set({ isOpen: true });
        },
        setIdIsGetting(isIdGetting) {
          set({ isIdGetting });
        },
        closeCart() {
          set({ isOpen: false });
        },
        setLoading(loading) {
          set({ loading });
        },
        async loadList() {
          const { setLoading, updateCartData, getCartId, refreshCartId }
            = get();
          try {
            setLoading(true);
            const cartId = await getCartId();
            const cartOrigin = await queryCart(cartId);
            await updateCartData(cartOrigin);
          } catch (e) {
            if (e.message.includes('cart shop general error')) {
              const cartId = await refreshCartId();
              const cartOrigin = await queryCart(cartId);
              await updateCartData(cartOrigin);
            }
          }
        },
        async updateCartData(data) {
          const { setLoading, updateCartDataSubtotal, updateRecommendList }
            = get();
          setLoading(true);
          const subTotal = updateCartDataSubtotal(data);
          set({ cartData: { ...data, subTotal } });

          let list = await updateCartItemsSoldOutByStore(data);
          list = updateCartListPrice(list);
          set({ list });

          updateRecommendList();
          setLoading(false);
        },
        async refreshCurrentCountry() {
          const { cartData, setLoading } = get();
          if (!cartData)
            return;

          setLoading(true);
          let list = await updateCartItemsSoldOutByStore(cartData);
          list = updateCartListPrice(list);
          set({ list });
          setLoading(false);
        },
        updateCartDataSubtotal(cartData) {
          if (!cartData?.lines?.edges)
            return null;
          const isNoEntireOrderDiscount
            = cartData.lines.edges.some(
              (item) => item.node.discountAllocations.length > 0,
            )
            && cartData.discountAllocations.length === 0
            && cartData.discountCodes.length === 0;

          const subtotalWithNoEntireOrderDiscount
            = isNoEntireOrderDiscount
            && cartData.lines.edges
              .reduce(
                (sum, current) =>
                  sum
                  + Number(
                    current.node.merchandise.product.priceRange.maxVariantPrice
                      .amount,
                  )
                  * current.node.quantity,
                0,
              )
              .toFixed(2);

          const savedWithNoEntireOrderDiscount
            = isNoEntireOrderDiscount
            && cartData.lines.edges
              .reduce(
                (sum, current) =>
                  sum
                  + (Number(
                    current.node.discountAllocations?.[0]?.discountedAmount
                      ?.amount,
                  ) || 0),
                0,
              )
              .toFixed(2);

          const subtotalAmount = isNoEntireOrderDiscount
            ? subtotalWithNoEntireOrderDiscount
            : cartData.cost?.subtotalAmount?.amount;

          const sumItemsSaved = cartData.lines.edges
            .reduce(
              (sum, current) =>
                sum
                + (Number(current.node.cost.subtotalAmount.amount)
                  - Number(current.node.cost.totalAmount.amount)),
              0,
            )
            .toFixed(2);

          const savedAmount = isNoEntireOrderDiscount
            ? savedWithNoEntireOrderDiscount
            : sumItemsSaved;

          return {
            savedAmount,
            subtotalAmount,
            totalAmount: cartData.cost?.totalAmount?.amount,
          };
        },
        async updateRecommendList() {
          const { getAllProducts } = useProductsStore.getState();
          const allProducts = await getAllProducts();
          const recommendList = getRecommendList(
            allProducts,
            get().list,
            'cart',
          );
          set({ recommendList });
        },
        async createCart() {
          const createdId = get().id;
          if (createdId)
            return createdId;
          try {
            const id = await createShopCart();
            set({ id });
            return id;
          } catch (e) {
            logger.error(e);
            throw e;
          }
        },
        async getCartId() {
          if (get().id)
            return get().id;
          return get().refreshCartId();
        },
        async refreshCartId() {
          set({ id: '' });
          const id = get().createCart();
          return id;
        },
        updateCartLineItemLoading(line, loading) {
          const cartList = get().list;
          const lineItemIndex = cartList.findIndex((it) => it.id === line.id);

          const updatedCartList = cartList.map((item, index) =>
            index === lineItemIndex ? { ...item, loading } : item,
          );
          set({ list: updatedCartList });
        },
        deleteCartLineItem(line) {
          return get().list.filter((it) => it.id !== line.id);
        },
        replaceLineItem(line) {
          const cartList = get().list;
          const lineItemIndex = cartList.findIndex(
            (it) => it.node?.id === line.id,
          );
          const lineItem = cartList.at(lineItemIndex);

          const updatedCartList = cartList.map((item, index) =>
            index === lineItemIndex ? { ...item, ...lineItem } : item,
          );

          set({ list: updatedCartList });
        },
        async updateCartLineQuantity(line, number) {
          const {
            updateCartData,
            list: cartList,
            updateCartLineItemLoading,
            deleteCartLineItem,
            replaceLineItem,
          } = get();
          const cartLineItemIndex = cartList.findIndex(
            (it) => it.id === line.id,
          );
          const cartLineItem = cartList.at(cartLineItemIndex);
          if (number === 0) {
            try {
              updateCartLineItemLoading(line, true);
              const cartData = await removeProduct(line.id);
              set({ list: deleteCartLineItem(line) });
              updateCartData(cartData);
            } catch (e) {
              logger.error(e);
            }
          } else {
            try {
              updateCartLineItemLoading(line, true);
              const cartData = await updateProduct(
                cartLineItem.id,
                cartLineItem.merchandise.id,
                number,
              );
              await updateCartData(cartData);
              replaceLineItem(line);
            } catch (e) {
              logger.error(e);
            }
          }
        },
        async addToCart(variantId, quantity = 1) {
          const {
            setLoading,
            updateCartData,
            updateCartLineItemLoading,
            openCart,
          } = get();
          let cartList = get().list;

          openCart();

          const cartLineItem = cartList.find(
            (it) => it.merchandise.id === variantId,
          );

          if (cartLineItem) {
            updateCartLineItemLoading(cartLineItem, true);
          } else {
            setLoading(true);
          }

          const cartData = await addProduct(variantId, quantity);
          await updateCartData(cartData);

          if (cartLineItem) {
            updateCartLineItemLoading(cartLineItem, false);
          } else {
            setLoading(false);
          }

          if (!cartData)
            throw new Error('cart update error');
          cartList = get().list;
          const product = cartList.find(
            (it) => it.merchandise?.id === variantId,
          );
          if (product) {
            trackAddToCart(product, 'cartLine');
          }

          return cartData;
        },
      })),
      {
        name: 'cart-store',
        partialize: (state) =>
          Object.fromEntries(
            Object.entries(state).filter(([key]) => ['id'].includes(key)),
          ),
      },
    ),
  ),
);

export default useCartStore;
