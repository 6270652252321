import { useMsg } from 'hooks/useMessages';

export default function useMessages() {
  const messagesGetter = useMsg('keystone.campaignModal');

  return {
    title: messagesGetter('modal.title'),
    subtitle: messagesGetter('modal.subtitle'),
    button: messagesGetter('modal.button'),
    campaigns: [1, 2].map((id) => messagesGetter(`campaigns.${id}.title`)),
  };
}
