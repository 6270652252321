export const LINKS = {
  keystoneSupportServiceDesk:
    'https://keystonewallet.atlassian.net/servicedesk/customer/portal/1',
  metamaskTutorial: 'https://www.youtube.com/embed/ixRIoGfbmTI',
  hackedSlowmist: 'https://hacked.slowmist.io/',
  suggestWalletAssets:
    'https://docs.google.com/forms/d/e/1FAIpQLSeaUn_GxkxNzNEeKx5s9CQFtB3YDW_Twmf0rARqeJVsUV2QlQ/viewform?usp=sf_link',
  homeTip: 'https://twitter.com/KeystoneWallet/status/1674065107100262401?s=20',
  trustpilotRate: 'https://www.trustpilot.com/review/keyst.one',
  trackOrder: 'https://shop.keyst.one/apps/17TRACK',
  ensDatabase: 'https://github.com/KeystoneHQ/ENS-meta-repo/releases',
  abiPack:
    'https://github.com/KeystoneHQ/Smart-Contract-Metadata-Registry/releases',
  affiliates: '/affiliates',
  shop: '/shop',
  shopProducts: {
    k3: '/shop/products/keystone-3',
    k3Pro: '/shop/products/keystone-3pro',
    k3ProBundlePack: '/shop/products/keystone-3pro-bundle-pack',
    k3ProLimitedEdition: '/shop/products/keystone-3-pro-limited',
    bundlePack: '/shop/products/keystone-bundle-pack',
    k3ProBtcHalving: '/shop/products/keystone-3pro-btc-halving',
    k3ProOkx: '/shop/products/keystone-3-pro-okx',
    tablet: '/shop/products/keystone-tablet',
    pouch: '/shop/products/keystone-leather-pouch',
    pouch2: '/shop/products/keystone-wallet-pouch-2',
    tabletPlus: '/shop/products/keystone-tablet-plus',
  },
  shopCompareOtherWallets: '/shop/products/keystone-3-pro#comparison',
  nftGen2: '/nft-gen2',
  githubRepo: 'https://github.com/KeystoneHQ',
  githubGen3Firmware: 'https://github.com/KeystoneHQ/keystone3-firmware',
  shippingRegions: '/shipping-regions',
  supportAssetsAndWallets: '/supported-wallets-and-assets',
  salesTermsAndConditions: '/sales-terms-and-conditions',
  resellars: '/resellers',
  whyKeystone: '/why-keystone',
  expertReviews: '/expert-reviews',
  web3Compatible: '/web3-compatible',
  securitySchemes: '/security-schemes',
  deviceAuth: '/authenticaiton',
  firmware: '/firmware',
  metamask: '/metamask',
  nft: '/nft',
  usbUpgrade: '/usb-upgrade',
  btcOnly: '/btc-only',
  sdCardStore:
    'https://www.westerndigital.com/products/memory-cards/sandisk-ultra-uhs-i-microsd#SDSQUNC-016G-AN6MA',
  aaaBatteryStore:
    'https://www.panasonic.com/global/energy/products/eneloop/en/lineup/eneloop.html',
  fromLedgerToKeystoneVideo: 'https://www.youtube.com/watch?v=jGIlie9W5Qg',
  fromLedgerToKeystoneVideoEmbed: 'https://www.youtube.com/embed/jGIlie9W5Qg',
  mall: {
    CN: 'https://shop165181922.m.youzan.com/wscgoods/detail/2xm1ie4r8tre275?scan=1&activity=none&from=kdt&qr=directgoods_4135289044&shopAutoEnter=1',
    MO: 'https://www.amazon.com/Keystone-Cryptocurrency-Hardware-Air-gapped-Securely/dp/B0CCRP4Z59?ref_=ast_sto_dp&th=1',
    TW: 'https://www.amazon.com/Keystone-Cryptocurrency-Hardware-Air-gapped-Securely/dp/B0CCRP4Z59?ref_=ast_sto_dp&th=1',
    KR: 'https://smartstore.naver.com/cryptowallet/products/9316549729',
    RU: 'https://sunscrypt.ru/product/hardware-wallets/apparatnyj-koshelek-keystone-3-pro/',
    TR: 'https://www.amazon.de/-/en/Keystone-Air-gapped-Cryptocurrency-Hardware-Fingerprint-3-Pro/dp/B0CCRP4Z59/ref=sr_1_3',
  },
  browsers: {
    chrome: 'https://www.google.com/chrome',
    edge: 'https://www.microsoft.com/edge',
  },
  mdn: {
    webusb:
      'https://developer.mozilla.org/en-US/docs/Web/API/USB#browser_compatibility',
  },
  discord: 'https://keyst.one/discord',
  telegram: 'https://t.me/KeystoneWallet',
  twitter: {
    en: 'https://twitter.com/KeystoneWallet',
    zh: 'https://twitter.com/KeystoneCN',
  },
  okx: '/okx',
  okxCollabs: '/okx-collabs',
  blog: {},
  coBranded: '/co-branded',
  roadmap: '/roadmap',
  partnership: {
    bluewallet: 'https://bluewallet.io',
    sparrow: 'https://sparrowwallet.com',
    nunchuk: 'https://nunchuk.io',
  },
  bitcoinHalving: '/bitcoin-halving',
  videos: {
    productVideo: 'https://keyst.one/statics/videos/keystone-3-pro.mp4',
  },
};
