import React, { useCallback, useEffect, useState } from 'react';
import { Collapse as ReactCollapse } from 'react-collapse';
import {
  CollapseContainer,
  CollapseContent,
  CollapseHeader,
  CollapseItem,
  ExpandIcon,
} from './style';

export default function Collapse({
  items = [],
  onToggle,
  accordion,
  hideBorder,
  customIcon,
}) {
  const [list, setList] = useState([]);

  useEffect(() => {
    setList(items);
  }, [items]);

  const toggleItem = useCallback(
    (item) => {
      setList((prev) =>
        prev.map((it) => ({
          ...it,
          isOpened:
            it.id === item.id ? !it.isOpened : !accordion ? it.isOpened : false,
        })),
      );
      onToggle?.({
        isOpened: !item.isOpened,
        data: item,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accordion],
  );

  return (
    <CollapseContainer>
      {list.map((it) => (
        <CollapseItem
          key={it.id}
          hideBorder={hideBorder}
          onClick={() => toggleItem(it)}
        >
          <CollapseHeader>
            {it.title}
            {customIcon
              ? (
                  customIcon(it.isOpened)
                )
              : (
                  <ExpandIcon open={it.isOpened} />
                )}
          </CollapseHeader>
          <ReactCollapse isOpened={it.isOpened}>
            <CollapseContent>{it.content}</CollapseContent>
          </ReactCollapse>
        </CollapseItem>
      ))}
    </CollapseContainer>
  );
}
