import { ProductsConfig } from 'config/products';
import {
  mainMsgs as messages,
  productListMsgs,
  productStatusMsgs,
} from '../messages';

const products = {
  bundlePack: {
    id: 'bundlePack',
    href: ProductsConfig.k3ProBundlePack.path,
    title: productListMsgs.bundlePack,
    img: require('./images/keystone-bundle.png'),
    blank: false,
    tags: [
      {
        tagText: productStatusMsgs.dollarSaved,
        tagColor: 'oranger',
        tagVariant: {
          discount: 10,
        },
      },
      {
        tagText: productStatusMsgs.hot,
        tagColor: 'pink',
      },
    ],
  },
  k3Pro: {
    id: 'k3Pro',
    href: ProductsConfig.k3Pro.path,
    title: productListMsgs.g3Pro,
    img: require('./images/keystone-3-pro.png'),
    tags: [
      {
        tagText: productStatusMsgs.hot,
        tagColor: 'pink',
      },
    ],
  },
  walletPouch: {
    id: 'walletPouch',
    href: ProductsConfig.pouch2.path,
    title: productListMsgs.t9,
    tag: true,
    tagColor: 'purple',
    tagText: productStatusMsgs.new,
    img: require('./images/keystone-wallet-pouch.png'),
    blank: false,
    tags: [
      {
        tagText: productStatusMsgs.new,
        tagColor: 'purple',
      },
    ],
  },
  limitedEdition: {
    id: 'k3ProLimitedEdition',
    href: ProductsConfig.k3ProLimitedEdition.path,
    title: productListMsgs.g3ProLimitedEdition,
    img: require('./images/keystone-3-pro-limited-edition.png'),
    blank: false,
  },
  nftCustomization: {
    id: 'k3ProNFTCustomization',
    href: ProductsConfig.k3ProNFTCustomization.path,
    title: productListMsgs.g3ProNFTCustomization,
    img: require('./images/keystone-3-pro-nft-customization.png'),
    blank: false,
  },
  tablet: {
    id: 'tablet',
    href: ProductsConfig.tablet.path,
    title: productListMsgs.t3,
    tag: false,
    img: require('./images/keystone-tablet.png'),
    blank: false,
  },
  tabletPlus: {
    id: 'tabletPlus',
    href: ProductsConfig.tabletPlus.path,
    title: productListMsgs.t4,
    tag: true,
    img: require('./images/keystone-tablet-plus.png'),
    blank: false,
    tags: [
      {
        tagText: productStatusMsgs.hot,
        tagColor: 'pink',
      },
    ],
  },
  tabletPunch: {
    id: 'tabletPunch',
    href: ProductsConfig.tabletPunch.path,
    title: productListMsgs.t5,
    tag: false,
    img: require('./images/keystone-tablet-punch.png'),
    blank: false,
  },
  tabletPouch: {
    id: 'tabletPouch',
    href: ProductsConfig.pouch.path,
    title: productListMsgs.t6,
    tag: false,
    img: require('./images/keystone-tablet-pouch.png'),
    blank: false,
  },
};

export const PRODUCTS_MENU_GROUP = [
  {
    id: 'recommend',
    title: messages.productsRecommend,
    list: [
      products.bundlePack,
      products.k3Pro,
      products.tabletPlus,
      products.walletPouch,
      products.tablet,
    ],
  },
  {
    id: 'hardware',
    title: messages.productsHardware,
    list: [
      products.bundlePack,
      products.k3Pro,
      products.limitedEdition,
      products.nftCustomization,
    ],
  },
  {
    id: 'accessories',
    title: messages.productsAccessories,
    list: [
      products.tabletPlus,
      products.walletPouch,
      products.tabletPunch,
      products.tablet,
      products.tabletPouch,
    ],
  },
  {
    id: 'bundle',
    title: messages.productsBundle,
    list: [products.bundlePack],
  },
];
