import { MD5 as md5 } from 'crypto-js';
import { isValidElement } from 'react';
import { lib as CryptoLib } from 'crypto-js/core';
import { v4 as uuidv4 } from 'uuid';
import { filter, isEmpty, isPlainObject } from 'lodash';
import Cookies from 'js-cookie';
import dayjs from 'plugins/dayjs';
import { TZ } from 'hooks/useCountdown';
import useAppearanceStore from 'stores/appearance';

export function getLanguage() {
  const lang = useAppearanceStore.getState().lang;
  return lang === 'zh' ? 'zh-TW' : 'en';
}

export function calculateDiscount(item, currency) {
  return Number(item.custom.discountPrice * currency.exchangeRate).toFixed(2);
}

export function calculateDiscountOff(cost) {
  const subTotalAmount = cost.subtotalAmount?.amount;
  const totalAmount = cost.totalAmount?.amount;
  return (
    (Number(subTotalAmount - totalAmount) / subTotalAmount)
    * 100
  ).toFixed(0);
}

export function transformPrice(price, exchangeRate = 1) {
  return (Number(price) * exchangeRate).toFixed(2);
}

const regTags = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
export function parseHtml(htmlStr) {
  if (!htmlStr)
    return '';
  const content = htmlStr
    .replace(regTags, '')
    .replace(/&nbsp;+/g, '\n')
    .replace(/\n+/g, '\n\n');
  return content.trim();
}

export function getUserId() {
  const userIdKey = 'keystone:user-id';
  let userId = Cookies.get(userIdKey);

  if (!userId) {
    userId = uuidv4();
    Cookies.set(userIdKey, userId, {
      expires: 365,
    });
  }
  return userId;
}

export function getGaClientId() {
  return window.ga && window.ga.getAll && window.ga.getAll()[0].get('clientId');
}

export function getDiffDaysNum(endDate, thresholdDay = 15) {
  if (!endDate)
    return null;
  const currDate = dayjs().tz(TZ);
  const days = dayjs(endDate).tz(TZ).diff(currDate, 'd');
  if (dayjs(endDate).tz(TZ).diff(currDate, 'h') < 0 || days > thresholdDay) {
    return null;
  }
  return days;
}

export function filterPlainObjectAndNotIsEmpty(list) {
  return filter(
    list,
    (v) => !isPlainObject(v) || (isPlainObject(v) && !isEmpty(v)),
  );
}

export function isDiscountCodesHasCode(codes, code) {
  return codes.length && new RegExp(codes.join('|'), 'i').test(code);
}

export function calculateMD5(blob) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(blob);
    reader.onloadend = () => {
      const wordArray = CryptoLib.WordArray.create(reader.result);
      const hash = md5(wordArray).toString().toUpperCase();
      resolve(hash);
    };
  });
}

export function calculateBufferMD5(buffer) {
  const wordArray = CryptoLib.WordArray.create(buffer);
  const hash = md5(wordArray).toString().toUpperCase();

  return hash;
}

export function genUniqueKey() {
  return Math.random().toString(36).slice(2);
}

export async function retryPromiseFn(fn, count = 3) {
  return new Promise((resolve, reject) => {
    const promise = function () {
      count--;
      return fn()
        .then((r) => {
          resolve(r);
        })
        .catch((e) => {
          if (!count) {
            reject(e);
          } else {
            promise();
          }
        });
    };
    promise();
  });
}

export function transferReactElementToText(node) {
  if (isValidElement(node)) {
    return node.props.children?.join(' ')?.trim();
  }
  if (Array.isArray(node)) {
    return node
      .map((it) => transferReactElementToText(it))
      .join(' ')
      .trim();
  }
  return node.trim();
}

export function getSourceUrlVersion(sourceUrl) {
  const versionReg = /\/([^/]+)\/[^/]+$/;
  const version = versionReg.exec(sourceUrl)?.[1];
  return version;
}
